<template>
    <section class="blockElement bg-white newLeaders">
        <!-- <div class="container-fluid">
            <div class="row">
                <div class="col-12 col-md-6">
                    <ul id="tabs-nav" class="zuluTabs d-flex align-items-center mb-0">
                        <li :class="[{'active' : $route.name == 'traders'}]"><router-link class="uppercase" to="/traders">TOP TRADERS</router-link></li>
                        <li :class="[{'active' : $route.name == 'combos'}]"><router-link class="uppercase" to="/traders/combos">COMBOS</router-link></li>
                        <li :class="[{'active' : $route.name == 'allTrader'}]"><router-link class="uppercase" to="/traders/winning/30">ALL TRADERS</router-link></li>
                    </ul>
                </div>
                <div class="col-12 col-md-6">
                    <div class="d-flex align-items-center justify-content-end position-relative">
                        <div class="form-search zuluInput me-md-5 me-3">
                            <input type="search" class="form-control" placeholder="Search Trader" v-model="searchTrder"  @keyup="searchTraders()"/> 
                            <a href="javascript:void(0)" class="searchIcon"><i class="search" data-feather="search"></i></a>
                        </div>
                        <div class="common-searchresult cutsom-w520" :class="[{'d-block' : store.searchTradersList.length && searchTrder}]">
                            <div class="pointer trader-list d-flex justify-content-between align-items-center" v-for="item,key in store.searchTradersList" :key="key" @click="nextUser(item.providerId)">
                                <div class="d-flex justify-content-between align-items-center">
                                    <div class="">
                                        <div class="zlds-avatar-small menuitem">
                                            <span class="position-relative d-block">
                                                <v-lazy-image class="rounded-circle" :src="static_vars.imagesURL+'webservices/Image.ashx?type=provider&size=XS&id='+item.providerId+'&ignore=false'" :alt="item.name"
                                                    :title="item.name">
                                            </span>
                                        </div>
        
                                    </div>
                                </div>
                                <div class="d-flex flex-column w-100 ps-2">
                                    <div class="medium f-18 secondary"><span class="">{{item.name}}</span>
                                    </div>
                                    <div class="d-flex justify-content-between">
                                        <div class="">
                                            <div class="small inactive">Following</div>
                                            <span class="">
                                                ${{Math.abs(item.amountFollowing) > 999 ? Math.sign(item.amountFollowing)*((Math.abs(item.amountFollowing)/1000).toFixed(1)) + 'k' : Math.sign(item.amountFollowing)*Math.abs(item.amountFollowing)}}</span>
                                        </div>
                                        <div class="">
                                            <div class="small inactive">Weeks</div>
                                            <span class="">{{item.weeks}}</span>
                                        </div>
                                        <div class="">
                                            <div class="small inactive">Investors</div>
                                            <span class="">{{item.followers}}</span>
                                        </div>
                                        <div class="">
                                            <div class="small inactive">ROI</div>
                                            <span class="">{{parseFloat(item.rorBasedRoi.toFixed(2)).toLocaleString()}}%</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <a href="javascript:void(0)" class="button borderBtn f-14">Join as a Trader</a>
                    </div>
                </div>
                <div class="col-12 col-lg-3">
                    <div class="d-flex align-items-start">
                        <span class="me-2">
                            <v-lazy-image width="50" height="50" class="rounded-circle" src="assets/images/following4.jpg"
                                alt="ZuluTrade" title="Zulu User" />
                        </span>
                        <div class="flex-shrink-0">
                            <h5 class="medium mb-0">Darius Robertson</h5>
                            <p class="small mb-0 gray">@drsrbrtsn22</p>
                            <div class="d-flex align-items-center">
                                <div class="d-flex align-items-center me-3">
                                    <p class="f-13 medium gray">Followers <span class="ms-1 bold">28</span></p>
                                </div>
                                <div class="d-flex align-items-center">
                                    <p class="f-13 medium gray">Following <span class="ms-1 bold">93</span></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="slidecontainer">
                        <input type="range" min="1" max="100" value="50" class="slider" id="myRange">
                        <p class="f-14"><span class="bold">2/5</span> Pending leaders to follow:</p>
                    </div>
                    <a class="button fillBtn f-12" href="javascript:void(0)">Follow</a>
                </div>
                <div class="col-12 col-lg-3">
                    <div class="d-flex align-items-center mb-2">
                        <h4 class="mb-0 me-3">Portfolio overview</h4>
                        <a href="javascript:void(0)" class="button zulu_btn rounded border-button small">View
                            Portfolio</a>
                    </div>
                    <div class="d-flex align-items-center">
                        <p class="f-14 mb-0 me-1">Total portfolio value</p>
                        <vue-feather type="eye-off" size="14"></vue-feather>
                    </div>
                    <h2 class="mb-2">$149,910 <sub>.47</sub></h2>
                    <div class="d-flex align-items-center mb-2">
                        <div class="total me-4">
                            <p class="f-14 mb-0">Total PnL</p>
                            <h6>$149,910.47</h6>
                        </div>
                        <div class="total">
                            <p class="f-14 mb-0">ROI</p>
                            <h6>+17,38%</h6>
                        </div>
                    </div>
                    <div class="belowButton d-flex align-items-center">
                        <a href="javascript:void(0)"
                            class="button fillBtn zulu_btn me-2 ps-0 small addButton"><vue-feather class="me-1 filled"
                                type="plus-circle" size="28"></vue-feather> Deposit</a>
                        <a href="javascript:void(0)" class="button zulu_btn rounded border-button small">Withdraw</a>
                    </div>
                </div>
                <div class="col-12 col-lg-3">
                    <div class="selectDay">
                        <p class="mb-0 gray f-14">Since</p>
                        <a class="dropdown-toggle" href="javascript:void(0)" data-bs-toggle="dropdown"
                            aria-expanded="false"> Yesterday
                        </a>
                        <ul class="dropdown-menu userLogged p-0">
                            <li>
                                <a href="javascript:void(0)">Yesterday</a>
                                <a href="javascript:void(0)">Today</a>
                                <a href="javascript:void(0)">Tomarrow</a>
                            </li>
                        </ul>
                    </div>
                </div> 
            </div>
        </div> -->
    </section>
</template>
<script>
import { myStore } from "@/store/pinia-store";
export default {
    setup() {
        const store = myStore();
        return { store };
    },
    data() {
        return {
            searchTrder: '',
        }
    },
    methods: {
        searchTraders() {
            if (this.searchTrder) {
                this.store.callFollowersSearchList({}, false, this.searchTrder)
            }
        },
        nextUser(userid) {
            if (userid) {
                let path = '/trader/' + userid + '/trading'
                this.$router.replace(path)
                this.searchTrder = ''
            }

        },
    }

}
</script>