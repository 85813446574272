<template>
    <div class="container-fluid mb-4 pb-md-3" v-if="store.topTrdaersList.length && store.topTrdaersList.length > 5 && Object.keys(this.store.topTrdaersList[5]).length && store.topTrdaersList[5].result && store.topTrdaersList[5].result.length">
        <div class="row align-items-center g-0">
            <div class="col-12 d-flex align-items-center mb-4">
                <h6 class="mb-0 bold f-22 me-2">{{ store.topTrdaersList[5].name }}</h6>
                <div class="tooltipbutton d-flex me-3">
                  <span class="tooltiptext f-12">
                      <p class="mb-0 line-4 f-14">{{store.topTrdaersList[5].descirption }}</p>
                  </span>
                  <p class="f-12 d-flex align-items-center justify-content-center mb-0"> <vue-feather class="smallSize" type="alert-circle"></vue-feather></p>
              </div>
                <router-link :to="'/traders/list/' + store.topTrdaersList[5].filterId" class="viewAll gray medium f-14 d-flex align-items-center">{{$t('top_traders.viewAll')}}
                                <vue-feather class="ms-1" type="chevron-right" size="16"></vue-feather> </router-link>
                <!-- <p class="f-12 gray mb-0">{{ store.topTrdaersList[5].descirption }}</p> -->
            </div>
        </div>
        <div class="row newAddLeader">
            <div class="col-12" v-show="store.topTrdaersList[5].result && store.topTrdaersList[5].result.length > 0">
                <carousel class="mt-0" :settings="$parent.carousleSettings" :breakpoints="$parent.breakpoints" >
                    <slide v-for="item, index in  store.topTrdaersList[5].result.slice(0,20)" :key="index">
                        <div class="card h-100 w-100 overflow-hidden" v-show="item.trader && Object.keys(item.trader).length">
                            <div class="card-body px-0 pb-0" v-show="item.trader.profile && Object.keys(item.trader.profile).length > 0">
                                <div class="text-center position-relative" v-show="item.trader.profile.name">
                                <div class="vueRisk px-3 d-flex align-items-start justify-content-between">
                                    <p :title="static_vars.riskTooltip" class="bTn mb-0 f-12 bold uppercase" :class="[{'blueBtn':item.trader.timeframeStats[Object.keys(item.trader.timeframeStats)[0]].timeFrameRisk==1},{'greenBtn':item.trader.timeframeStats[Object.keys(item.trader.timeframeStats)[0]].timeFrameRisk==2},{'yellowBtn':item.trader.timeframeStats[Object.keys(item.trader.timeframeStats)[0]].timeFrameRisk==3},{'redBtn':item.trader.timeframeStats[Object.keys(item.trader.timeframeStats)[0]].timeFrameRisk==4},{'pinkBtn':item.trader.timeframeStats[Object.keys(item.trader.timeframeStats)[0]].timeFrameRisk==5}]" v-if="item.trader.timeframeStats[Object.keys(item.trader.timeframeStats)[0]].hasOwnProperty('timeFrameRisk')">{{$t('top_traders.risk')}} {{item.trader.timeframeStats[Object.keys(item.trader.timeframeStats)[0]].timeFrameRisk}}</p>
                                    <router-link :to="`/trader/${item.trader.profile.id}/trading?t=${Object.keys(item.trader.timeframeStats)[0]}&m=1`" class="circleImg d-inline-block mb-3">
                                       <v-lazy-image height="70" width="70" class="rounded-circle" :src="(!item.trader.profile.photoApproved) ? (item.trader.profile.customerAvatarUrl) ? item.trader.profile.customerAvatarUrl : static_vars.imagesURL + 'webservices/Image.ashx?type=user&size=XL&id=' + item.trader.profile.zuluAccountId + '&ignore=false' : static_vars.imagesURL + 'webservices/Image.ashx?type=user&size=XL&id=' + item.trader.profile.zuluAccountId + '&ignore=false'" :alt="item.trader.profile.name" :title="item.trader.profile.name" />
                                    </router-link>
                                    <div class="d-flex align-items-center">
                                      <a class="me-1" href="javascript:void(0)" @click="$parent.AddWatchList(item)" :class="{'disabled' : store.customerDetail?.readOnly}"><vue-feather type="star" size="19" :class="[{'filled' : $parent.isFollowed(+ item.trader.providerId)}]"></vue-feather></a>
                                        <div class="position-relative">
                                        <a href="javascript:void(0)" class="iconButton ms-1" @click="(opendots != item.trader.profile.zuluAccountId) ? opendots = item.trader.profile.zuluAccountId : opendots = -1">
                                            <i class="gray fa fa-ellipsis-v" aria-hidden="true"></i>
                                        </a>
                                        <ul class="dropdown-menu" data-popper-placement="bottom-start" :class="[{ 'show': opendots ==  item.trader.profile.zuluAccountId }]">
                                            <li><a class="f-12 dropdown-item" href="javascript:void(0);" @click="sharePop = item.trader.providerId">{{$t('top_traders.share')}}</a></li>
                                            <li><router-link :to="{path:'/compare',query:{p:item.trader.profile.id}}" class="f-12 dropdown-item">{{$t('top_traders.compare')}}</router-link></li>
                                        </ul>
                                        </div>
                                    </div>
                                </div>
                                <div class="title px-2">
                                    <router-link :to="`/trader/${item.trader.profile.id}/trading?t=${Object.keys(item.trader.timeframeStats)[0]}&m=1`">
                                      <h6 class="mb-1 f-16">{{ item.trader.profile.name }}</h6>
                                      <div class="element d-flex align-items-center justify-content-center mb-3">
                                        <div class="element d-flex align-items-center justify-content-center me-2" v-if="item.trader.overallStats.followers">
                                            <p class="gray medium f-12 mb-0 me-1">{{$t('top_traders.copiers')}}</p>
                                            <p class="f-12 bold mb-0">{{ item.trader.overallStats.followers }}</p>
                                        </div>
                                        <div class="element d-flex align-items-center justify-content-center" v-if="item.trader.overallStats && item.trader.overallStats.includedInWatchlist">
                                            <p class="gray medium f-12 mb-0 me-1">{{$t('top_traders.followers')}}</p>
                                            <p class="f-12 bold mb-0">{{ item.trader.overallStats.includedInWatchlist || 0 }}
                                            </p>
                                        </div>
                                      </div>
                                    </router-link>
                                    <div class="element"  v-if="item.trader.timeframeStats[Object.keys(item.trader.timeframeStats)[0]].hasOwnProperty('annualizedRorBasedRoi')">
                                      <router-link :to="`/trader/${item.trader.profile.id}/trading?t=${Object.keys(item.trader.timeframeStats)[0]}&m=1`">
                                      <p class="mb-0 bold px-1 d-inline-flex" :class="ROIClass(item)" v-if="item.trader.timeframeStats[Object.keys(item.trader.timeframeStats)[0]].hasOwnProperty('annualizedRorBasedRoi')">
                                      {{parseInt(item.trader.timeframeStats[Object.keys(item.trader.timeframeStats)[0]].annualizedRorBasedRoi) >= 0 ? '+' : '-'}}{{
                                            parseFloat(item.trader.timeframeStats[Object.keys(item.trader.timeframeStats)[0]].annualizedRorBasedRoi).toFixed(1)
                                            || 0
                                      }}%</p>
                                      </router-link>
                                    </div>
                                </div>
                                <div class="chartElement" :id="'topTraders' + item.trader.profile.zuluAccountId + '_' + store.topTrdaersList[5].order" style="width: 100%; height: 126px;"></div>
                                </div>
                            </div>
                        </div>
                    </slide>
                    <template #addons="{ slidesCount }">
                        <navigation v-if="slidesCount > 5"/>
                    </template>
                </carousel>
            </div>
        </div>
    </div>
    <sharePop :shareURL="sharePop" :close="()=>sharePop = ''" v-if="sharePop" :shareType="'leaderProfile'"></sharePop>
</template>
<script>
  import { myStore } from "@/store/pinia-store";
  import * as am5 from "@amcharts/amcharts5";
  import * as am5xy from "@amcharts/amcharts5/xy";
  import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
  import am5themes_Responsive from "@amcharts/amcharts5/themes/Responsive";
  import sharePop from "@/views/social-feed/share-pop";
  import { Carousel, Slide, Navigation } from 'vue3-carousel'
  export default {
    setup() {
      const store = myStore();
      return { store };
    },
    data() {
      return {
         opendots : false,
         sharePop: '',
      };
    },
    components: {
        Carousel,
        Slide,
        Navigation,
        sharePop,
    },
    methods: {
      ROIClass(item){
          if(parseInt(item.trader.timeframeStats[Object.keys(item.trader.timeframeStats)[0]].annualizedRorBasedRoi) >= 0){
              return 'greenView'
          }else{
              return 'redView'
          }
      },
      drawROIChart(id, data) {
        let chartId = "roiTraders" + id;
        let d = am5.registry.rootElements.find((i) => i.dom && i.dom.id === chartId);
        d?.dispose();
        var root = am5.Root.new(chartId);
        root.interfaceColors.set("grid", am5.color(0xffffff));
        root.setThemes([am5themes_Animated.new(root), am5themes_Responsive.new(root)]);
        root.numberFormatter.set("numberFormat", "#,###.00");
        var chart = root.container.children.push(
          am5xy.XYChart.new(root, {
            focusable: false,
          })
        );

        // Add cursor
        // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
        var cursor = chart.set("cursor", am5xy.XYCursor.new(root, {}));
        cursor.lineY.set("visible", false);
        cursor.lineX.set("visible", false);
        // Create axes
        // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
        var xAxis = chart.xAxes.push(
          am5xy.DateAxis.new(root, {
            maxDeviation: 0.2,
            visible: false,
            baseInterval: {
              timeUnit: "day",
              count: 1,
            },
            renderer: am5xy.AxisRendererX.new(root, {
              minGridDistance: 10,
            }),
          })
        );
        // var label2 = am5.Label.new(root, {
        //   text: "[#666] Date [/]",
        //   x: am5.p50,
        //   centerY: am5.p50,
        //   fontSize: 12,
        // });
        // xAxis.children.push(label2);
        var yAxis = chart.yAxes.push(
          am5xy.ValueAxis.new(root, {
            visible: false,
            renderer: am5xy.AxisRendererY.new(root, {}),
          })
        );
        // var label1 = am5.Label.new(root, {
        //     rotation: -90,
        //     text: "[#666] Ranking [/]",
        //     y: am5.p50,
        //     centerX: am5.p50,
        //     fontSize: 12,
        //   });
        // yAxis.children.unshift(label1);

        chart.get("colors").set("colors", [am5.color(0xff7200), am5.color(0x4572a7)]);

        let yRenderer = yAxis.get("renderer");
        yRenderer.grid.template.setAll({
          stroke: am5.color(0x666666),
          strokeWidth: 1,
        });
        var legend = chart.bottomAxesContainer.children.push(
          am5.Legend.new(root, {
            x: am5.percent(50),
            centerX: am5.percent(50),
            layout: root.horizontalLayout,
            useDefaultMarker: true,
            // paddingTop: 15,
          })
        );

        legend.labels.template.setAll({
          fontSize: 12,
          fontWeight: "600",
        });
        legend.markerRectangles.template.setAll({
          cornerRadiusTL: 20,
          cornerRadiusTR: 20,
          cornerRadiusBL: 20,
          cornerRadiusBR: 20,
        });
        legend.markers.template.setAll({
          width: 13,
          height: 13,
        });
        var series = chart.series.push(
          am5xy.LineSeries.new(root, {
            name: "Series",
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: "y",
            valueXField: "x",
            tooltip: am5.Tooltip.new(root, {
              labelText: "{valueY}",
            }),
          })
        );
        series.strokes.template.setAll({
          strokeWidth: 2,
        });
        // Set data
        series.data.setAll(data);
        //legend.data.push(series);
        series.appear(1000);
        chart.zoomOutButton.set("forceHidden", true);
        xAxis.get("renderer").labels.template.setAll({
          fill: root.interfaceColors.set("fill", am5.color("#666666")),
          fontSize: 12,
        });
        yAxis.get("renderer").labels.template.setAll({
          fill: root.interfaceColors.set("fill", am5.color("#ffa35a")),
          fontSize: 12,
        });
        chart.appear(1000, 100);
      },
      drawChart() {
        if (this.store.topTrdaersList.length > 5 && Object.keys(this.store.topTrdaersList[5]).length) {
          if (this.store.topTrdaersList[5].result && this.store.topTrdaersList[5].result.length > 0) {
            this.store.topTrdaersList[5].result.slice(0, 20).forEach((item) => {
              if (
                item.trader &&
                Object.keys(item.trader).length &&
                item.trader.profile &&
                Object.keys(item.trader.profile).length &&
                item.trader.profile.zuluAccountId &&
                item.trader.timeFrameGraph &&
                item.trader.timeFrameGraph.series &&
                item.trader.timeFrameGraph.series.length &&
                item.trader.timeFrameGraph.series[0].data &&
                item.trader.timeFrameGraph.series[0].data.length
              ) {
                am5.ready(() => {
                  setTimeout(() => {
                    this.$parent.tradersChart(item.trader.profile.zuluAccountId + "_" + this.store.topTrdaersList[5].order, item.trader.timeFrameGraph.series[0].data);
                  }, 300);
                });
              }
            });
          }
        }
      },
    },
  };
</script>
